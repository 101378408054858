import { Component, inject, OnInit } from '@angular/core';
import { UserService } from '../core/services/user/user.service';
import { debounceTime, delay, filter, first, map, shareReplay, switchMap, takeWhile, tap } from 'rxjs/operators';
import { WindowService } from '../core/services/window.service';
import { PlatformService } from '../core/services/platform.service';
import { combineLatest, fromEvent, of, timer } from 'rxjs';
import { ToastMessageService } from '../core/modules/toast-message/toast-message.service';
import {
  CookieMessageContentComponent,
} from '../core/modules/toast-message/content/cookie-message-content/cookie-message-content.component';
import { BadgeService } from '../core/services/badge.service';
import { TranslationService } from '../core/shared/translation/translation.service';
import { AuPaymentRestrictService } from '../core/services/au-payment-restrict.service';
import { GameIframeService } from '../core/services/games/game-iframe.service';
import { JackpotService } from './jackpot/jackpot.service';
import { LocalstorageService } from '../core/services/localstorage.service';
import { WrSocketService } from '../core/services/wr-socket.service';
import { GlobalEventsService } from '../core/services/global-events.service';
import { InstallAppService } from '../core/services/install-app.service';
import { ModalService } from '../core/modal-v2/modal.service';
import { ScrollService } from '../core/services/scroll.service';
import { GroupsService } from '../core/services/groups.service';
import { StaticContentService } from '../core/services/static-content.service';
import { StaticPageService } from './static-page/static-page.service';
import { Router, RouterOutlet } from '@angular/router';
import { ArabicService } from '../core/services/arabic.service';
import { UbidexService } from '../core/services/ubidex.service';
import { FraudCheckService, LOCAL_STORAGE_FRAUD_KEY_DEPOSIT } from '../core/services/fraud-check.service';
import { GameIframeComponent } from '../core/shared/standalone/game-iframe/game-iframe.component';
import { SetImgAltDirective } from '../core/shared/directives/set-img-alt.directive';
import { InstallAppBannerComponent } from '../core/shared/components/install-app-banner/install-app-banner.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { StickyBonusComponent } from '../core/shared/components/sticky-bonus/sticky-bonus.component';
import { ToastMessageComponent } from '../core/modules/toast-message/toast-message.component';
import { AppShellNoSSRDirective } from '../core/shared/directives/app-shell/app-shell-no-ssr.directive';
import { LazyLoadImagesDirective } from '../core/shared/directives/ngx-lazy-load-images.directive';
import { UserInfoService } from '../core/services/user/user-info.service';
import { UserSubscriptionsService } from '../core/services/user/user-subscriptions.service';
import { CookieService } from 'ngx-unificator/services';
import { AB_TEST_LIST } from '../core/ab-test/ab-test.data';
import { SsPaymentsV2Service } from '../core/vendor/ss-payments-v2/ss-payment.service';
import { AbTestNewService } from '../core/ab-test/ab-test.service';
import { WebSocketService } from '../core/services/web-socket.service';
import { CommonDataService } from '../core/services/common-data.service';
import { INSTALL_APP_GROUP } from '../core/services/user/data/user-group.data';
import { BONUS_SHOP_TYPE, BonusStoreService } from '../core/services/bonus-store.service';
import { UserBonusesService } from '../core/services/user/user-bonuses.service';
import { ValentineService } from './promo/valentine/valentine.service';
import { MascotService } from '../core/services/mascot.service';

export const regAbTestGroupAfter15December = 'ID648'; // exist if user reg after 21.11.2023

export const regAbTestGroupThird = 'ID648'; // exist if user reg after 25.01.2024

export const INSTALL_APP_COOKIE = 'mobile-app';
@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
    imports: [LazyLoadImagesDirective, RouterOutlet, AppShellNoSSRDirective, ToastMessageComponent, StickyBonusComponent, InstallAppBannerComponent, SetImgAltDirective, NgClass, GameIframeComponent, AsyncPipe]
})
export class PageComponent implements OnInit {
  private _user = inject(UserService);
  private _scroll = inject(ScrollService);
  private _window = inject(WindowService);
  private _platform = inject(PlatformService);
  private _toastMessage = inject(ToastMessageService);
  private _cookies = inject(CookieService);
  private _badge = inject(BadgeService);
  private _modals = inject(ModalService);
  private _abTestNew = inject(AbTestNewService);
  private _auRestrict = inject(AuPaymentRestrictService);
  private _jackpot = inject(JackpotService);
  private _storage = inject(LocalstorageService);
  private _groups = inject(GroupsService);
  translations = inject(TranslationService);
  private _wrSocket = inject(WrSocketService);
  private _events = inject(GlobalEventsService);
  gameIframe = inject(GameIframeService);
  installApp = inject(InstallAppService);
  private _valentine = inject(ValentineService);
  private _static = inject(StaticContentService);
  private _page = inject(StaticPageService);
  private _router = inject(Router);
  private _arabic = inject(ArabicService);
  private _ubidex = inject(UbidexService);
  private _fraud = inject(FraudCheckService);
  private _userInfo = inject(UserInfoService);
  private _subscriptions = inject(UserSubscriptionsService);
  private _ssPayment = inject(SsPaymentsV2Service);
  private _ws = inject(WebSocketService);
  private _commonData = inject(CommonDataService);
  private _bonusShop = inject(BonusStoreService);
  private _bonuses = inject(UserBonusesService);
  private _mascot = inject(MascotService);


  /**
   * Is to top button visible
   */
  public toTopVisible: boolean;

  public AB_TEST_LIST = AB_TEST_LIST;

  ngOnInit() {
    if (!this._arabic.isAr) {
      this._initValentinePromo();
    }

    if (this._platform.isBrowser) {
      this._handleAuthActions();
      this._handleScroll();
      this._initJackpot();
      this._showCookiesMessage();
      this._listenJackpotWinnerAndOpenModal();
      this._jackpot.getTotalJackpot();
      this._listenLinkClickAndOpenExchangeModal();
      this._sendUbidexVisit();
      this._initAbTests();
      this.installApp.checkIsNativeAppEnabled();
      this._initBonusShop();

      if (!this._user.auth) {
        this._subscriptions.resolveReceiveSmsPromosByGeo();
        this._subscriptions.resolveAcceptedTermsByGeo();
      }
    }
  }

  private _initValentinePromo() {
    if (this._platform.isBrowser) {
      this._valentine.initMenuLink();
      this._valentine.initWelcomePromoModal();
    }
  }

  /**
   * Init jackpot by ab test
   * @private
   */
  private _initJackpot() {
    this._jackpot.initJackpot('available-jackpot');
  }

  /**
   * Load badges info and
   * add user to test group
   */
  private _handleAuthActions() {
    this._user.auth$.pipe(
      filter(auth => !!auth),
      first(),
    ).subscribe(() => {
      this._wrSocket.initWrSocket();
      this._badge.loadBadgeInfo();
      this._events.isLeaveBrowserTab$.subscribe((isLeave: boolean) => {
        isLeave ? this._wrSocket.sendEventMouseout() : this._wrSocket.sendEventMousein();
      });
      this._storage.set('user_id', this._user.info.id);
      this._abTestNew.syncUserABTestGroupOnAuth();
      this._addGroupAfterRegShowModal();
      this._addGroupAfterRegJanuaryByAbTest();
      this._ssPayment.loadApiLibrary();
      this._ubidex.onVisit(this._user.info);
      this._listenUserBalance();
      this._addUserToMobileAppGroup();
      this._listenDepositSuccess();
      this._mascot.resolveUserMascots();
    });
  }

  private _listenDepositSuccess() {
    this._wrSocket.depositSuccessNotify$.pipe(
      first(),
      filter(() => this._fraud.isBodyFraudStorageLength(LOCAL_STORAGE_FRAUD_KEY_DEPOSIT)),
      switchMap(({ uid }) => this._fraud.postFraudData(this._storage.get(LOCAL_STORAGE_FRAUD_KEY_DEPOSIT), uid)),
      tap(() => this._storage.clearItem(LOCAL_STORAGE_FRAUD_KEY_DEPOSIT)),
    ).subscribe();
  }

  /**
   * To top button click handler
   */
  onToTopClick() {
    this._scroll.scrollToElement(this._scroll.documentElement);
  }

  /**
   * Window scroll handler
   *
   * @private
   */
  private _handleScroll() {
    if (this._platform.isBrowser) {
      fromEvent(this._window.nativeWindow, 'scroll').pipe(
        debounceTime(150),
      ).subscribe(() => {
        this.toTopVisible = this._window.nativeWindow.pageYOffset > 300;
      });
    }
  }


  /**
   * Show cookie message after AU payment restrict check
   */
  private _showCookiesMessage() {
    if (!this._cookies.check('isCookiesAccepted')) {
      if (this._platform.isBrowser) {
        this._auRestrict.text$.pipe(
          first(),
          tap(() => {
            const message = this._toastMessage.cookie(CookieMessageContentComponent, null, true, false);

            message.callback = () => {
              this._showCookiesMessage();
            };
          }),
        ).subscribe();
      }
    }
  }

  /**
   * Listen jackpot winner and open modal
   * @private
   */
  private _listenJackpotWinnerAndOpenModal() {
    this._jackpot.wsWinnerJackpot$.pipe(
      tap(async (jackpot) => {
        const component = await import('../core/modal-v2/components/lazy/jackpot-winning/jackpot-winning.component');
        await this._modals.openLazy(component?.JackpotWinningComponent, {
          template: 'CLEAR',
          data: { jackpot },
        });
      }),
    ).subscribe();
  }

  /**
   * Open currency exchange modal
   */
  private _listenLinkClickAndOpenExchangeModal() {
    this._events.isExchangeRateLinkClick$.pipe(
      filter(slug => !!slug),
      tap(async slug => {
        const component = await import('../core/modal-v2/components/lazy/title-and-content-modal/title-and-content-modal.component');
        await this._modals.openLazy(component?.TitleAndContentModalComponent, {
          template: 'CLEAR',
          data: {
            content$: this._page.item({ slug }),
          },
        });
      }),
    ).subscribe();
  }

  /**
   * Add user to ab test group if reg after 25.01.2024
   * @private
   */
  private _addGroupAfterRegJanuaryByAbTest() {
    of(true).pipe(
      delay(3000),
      filter(() => this._groups.isExistGroup(regAbTestGroupThird)),
      tap(() => this._abTestNew.loadABTest$('DEVSS5127')),
    ).subscribe();
  }


  /**
   * Add user to ab test and show modal if reg after 15/12..But returned logic for https://jira.werush.co/browse/DEVSS-5298 task
   * @private
   */
  private _addGroupAfterRegShowModal() {
    if (this._platform.isBrowser) {
      of(true).pipe(
        delay(3000),
        filter(() => this._groups.isExistGroup(regAbTestGroupAfter15December)),
        switchMap(() => this._abTestNew.loadABTest$('DEVSS5298')),
        filter(() => {
          const routesIgnor = ['403', '404', 'register', 'login', 'deposit', 'cashout', 'games', 'promo', 'play'];
          return !routesIgnor.some((e) => this._router.url.includes(e))
            && this._groups.isExistGroup(AB_TEST_LIST.DEVSS5298.headerMainGroup)
            && !this._groups.isExistGroup('ID680');
        }),
        delay(300000),
        switchMap(() => this._static.item({ slug: 'bets-test-popup' })),
        filter(res => !!res && res[0]),
        map(res => res[0]),
        tap(async (resp) => {
          const component = await import('../core/modal-v2/components/lazy/after-reg-modal/after-reg-modal.component');
          await this._modals.openLazy(component?.AfterRegModalComponent, {
            template: 'CLEAR',
            closeAfterRouteChange: true,
            disableOverlayClosing: true,
            data: resp,
          });
        }),
        switchMap(() => this._groups.addToGroup('ID680')),
      ).subscribe();
    }
  }

  /**
   * The function `_sendUbidexVisit` checks if the user is authenticated and calls the `onVisit` method
   * of the `_ubidex` object if not.
   */
  private _sendUbidexVisit() {
    if (!this._user.auth) {
      this._ubidex.onVisit();
    }
  }

  private _addUserToMobileAppGroup() {
    if (this._cookies.check(INSTALL_APP_COOKIE)) {
      this._groups.addToGroup(INSTALL_APP_GROUP).pipe(
        first(),
      ).subscribe()
    }
  }

  private _initAbTests() {
    this._initPopularPaymentAbTest();
    this._newUsersTest();
    this._userResultsTest();
    this._initSlotTest();
    this._cashoutCanceledTest();
    this._initUpsaleTest();
  }

  private _listenUserBalance() {
    this._ws.userBalance$.pipe(
      map(balance => {
        return {
          ...balance,
          amount: this._commonData.subunitsToUnits(balance.amount_cents, balance.currency),
        };
      }),
      tap((balance) => this._user.currentCurrency.amount = balance.amount),
    ).subscribe();
  }


  private _initPopularPaymentAbTest() {
    if (this._userInfo.isCA) {
      this._static.item({ slug: 'popular-payments-ids' }).pipe(
        filter((data) => !!data && data[0]),
        map((data) => {
          this._abTestNew.popularPaymentsDEVSS5667 = Object.keys(data[0].Grouplist);
          return this._abTestNew.popularPaymentsDEVSS5667;
        }),
      ).subscribe();
    }
  }

  private _initSlotTest() {
    this._abTestNew.loadABTest$('DEVSS6504').pipe(
      first(),
      tap(() => this._initSlotModal())
    ).subscribe()
  }

  /**
   * Private init slot modal
   * @private
   */
  private _initSlotModal() {
    if (this._platform.isBrowser) {
      const routesIgnore = ['register', 'login', '403', '404', 'maintenance'];

      timer(5000).pipe(
        takeWhile(() => !this._user.auth),
        filter(() => !routesIgnore.some(route => this._router.url.includes(route)) && !this._cookies.check('--slots-modal-display') && AB_TEST_LIST.DEVSS6504.resolvedValue === AB_TEST_LIST.DEVSS6504.abTestGroupIds.V2),
        switchMap(() => combineLatest([this._static.item({slug: 'ss-slot-welcome'}), this._bonuses.allCmsBonusList$])),
        filter(() => !this._cookies.check('--slots-modal-display')),
        filter(([modalData, welcomeBonusList]) => modalData?.[0] && !!welcomeBonusList?.length),
        tap(async ([modalData, welcomeBonus]) => {
          if (!this._user.auth) {
            const component = await import('../core/modal-v2/components/lazy/slots-welcome-modal/slots-welcome-modal.component');
            await this._modals.openLazy(component.SlotsWelcomeModalComponent, {
              template: 'CLEAR',
              data: {welcomeBonus: welcomeBonus[0], modalData: modalData[0]},
            });
          }
        })
      ).subscribe();
    }
  }


  private _newUsersTest() {
    this._static.item({ slug: 'social-stags-test' }).pipe(
      filter((data) => !!data?.length),
      tap(() => this._abTestNew.loadABTest$('DEVSS5757')),
    ).subscribe();
  }

  private _userResultsTest() {
    this._abTestNew.loadABTest$('DEVSS6305');
  }

  private _initBonusShop() {
    this._bonusShop.bonusStorePage$ = combineLatest([
        this._bonusShop.getAndMappedPage$(BONUS_SHOP_TYPE.CASH),
        this._bonusShop.getAndMappedPage$(BONUS_SHOP_TYPE.COINS),
      ],
    ).pipe(
      shareReplay(1)
    );
  }

  private _cashoutCanceledTest() {
    this._abTestNew.loadABTest$('DEVSS6549');
  }

  private _initUpsaleTest() {
    this._abTestNew.loadABTest$('DEVSS6675');
  }
}
