import { Injectable, inject } from '@angular/core';
import { SsApiService } from './api/ss-api.service';
import { Observable, ReplaySubject } from 'rxjs';
import { UserService } from './user/user.service';
import { filter, tap } from 'rxjs/operators';
import { LoyaltyStatusesCommon, LoyaltyStatusesNinja, LoyaltyStatusesSamurai } from '../helpers/loyalty-statuses';

export enum UserGroup {
  SAMURAI = 'Samurai',
  NINJA = 'Ninja'
}
export enum BonusesStatuses {
  ALL_WELCOME_OFFERS_USED = 'ID341',
  ALL_SPECIAL_WELCOME_USED = 'ID75',
  DAILY_REWARD = 'ID330',
}


@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  private _api = inject(SsApiService);
  private _user = inject(UserService);


  private _loyaltyGroup: UserGroup = null;
  private _isStatusBiggerThreeAndSideNotSelected: boolean;

  /**
   * For change statuses list when selected group in slider
   */
  public selectedLoyaltyGroup: ReplaySubject<any> = new ReplaySubject<any>();

  constructor() {
    this._user.auth$.pipe(
      filter(auth => auth),
      tap(() => this.defineCurrentLoyaltyGroup())
    ).subscribe();
  }

  get loyaltyGroup(): UserGroup {
    return this._loyaltyGroup;
  }

  get isStatusBiggerThreeAndSideNotSelected() {
    return this._isStatusBiggerThreeAndSideNotSelected;
  }

  get userGroups() {
    return UserGroup;
  }

  /**
   * Returns current player loyalty group
   */
  defineCurrentLoyaltyGroup(): UserGroup {
    if (!this._user.auth$ || !this._user.info.statuses.length) {
      return null;
    }

    const statusGroup = this._user.info.statuses.find(status => Object.values(UserGroup).includes(status.id));

    this._loyaltyGroup = statusGroup && statusGroup.id || null;

    const currentStatusIndex = LoyaltyStatusesCommon.findIndex(commonStatus => this._user.info.statuses.some(s => s.id === commonStatus.id)) + 1;
    const isSamuraiStatus = LoyaltyStatusesSamurai.some(samuraiStatus => this._user.info.statuses.some(s => s.id === samuraiStatus.id));
    const isNinjaStatus = LoyaltyStatusesNinja.some(ninjaStatus => this._user.info.statuses.some(s => s.id === ninjaStatus.id));
    this._isStatusBiggerThreeAndSideNotSelected = currentStatusIndex >= 3 && !isSamuraiStatus && !isNinjaStatus;
  }

  /**
   * Add user to provided group
   *
   * @param group
   */
  public addToGroup(group: UserGroup | string): Observable<any> {
    return this._api.postPlayerGroups({
      groups: {
        add: [group]
      }
    });
  }

  /**
   * Remove user from provided group
   *
   * @param group
   */
  public removeFromGroup(group: UserGroup): Observable<any> {
    return this._api.postPlayerGroups({
      groups: {
        remove: [group]
      }
    });
  }

  /*
 * Is exist group in user`s groups
 *
 * @param group
 */
  public isExistGroup(groupId: number | string): boolean {
    if (!this._user.auth || !this._user.info.statuses.length) {
      return null;
    }
    return Boolean(this._user.info.statuses.find(status => status.id === groupId));
  }
}
