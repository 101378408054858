import { AfterViewInit, Component, ElementRef, inject, viewChild } from '@angular/core';
import { SideNavService } from './side-nav.service';
import { SsApiService } from '../../../services/api/ss-api.service';
import { PlatformService } from '../../../services/platform.service';
import { UserService } from '../../../services/user/user.service';
import { BadgeService } from '../../../services/badge.service';
import { LanguageService, NonLocalizedRoutes } from '../../../services/language/language.service';
import { EnvironmentService } from '../../../services/environment.service';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { TranslationService } from '../../translation/translation.service';
import { Router, RouterLink } from '@angular/router';
import { AccordionDirective } from '../../directives/accordion.directive';
import { delay, filter, finalize, first, map, tap } from 'rxjs/operators';
import { ServiceWorkerService } from '../../../services/service-worker.service';
import { SeoService } from '../../../services/seo.service';
import { JackpotService } from '../../../../page/jackpot/jackpot.service';
import { WrSocketService } from '../../../services/wr-socket.service';
import { Observable, of } from 'rxjs';
import { TournamentsService } from '../../../../page/tournaments/tournaments.service';
import { ZendeskChatService } from '../../../services/zendesk/zendesk-chat.service';
import { GroupsService } from '../../../services/groups.service';
import { MotivateWithdrawalService } from '../../../services/motivate-withdrawal.service';
import { LotteryService } from '../../../services/lottery.service';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { ArabicService } from '../../../services/arabic.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { CurrencyFormatPipe } from '../../pipes/currency-format.pipe';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { AppShellNoSSRDirective } from '../../directives/app-shell/app-shell-no-ssr.directive';
import { SetImgAltDirective } from '../../directives/set-img-alt.directive';
import { AppShellRenderSSRDirective } from '../../directives/app-shell/app-shell-render-ssr.directive';
import { AsyncPipe, NgClass } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { BonusStoreService } from '../../../services/bonus-store.service';
import { InstallAppService } from '../../../services/install-app.service';
import { ValentineService } from '../../../../page/promo/valentine/valentine.service';

@UntilDestroy()
@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    imports: [
        ClickOutsideDirective,
        NgClass,
        AppShellRenderSSRDirective,
        SetImgAltDirective,
        AppShellNoSSRDirective,
        RouterLink,
        RouterLinkDirective,
        AccordionDirective,
        AsyncPipe,
        CurrencyFormatPipe,
        TranslatePipe,
    ]
})
export class SideNavComponent implements AfterViewInit {
  private _ssApi = inject(SsApiService);
  private _platform = inject(PlatformService);
  private _gtm = inject(GoogleTagManagerService);
  private _translationService = inject(TranslationService);
  private _router = inject(Router);
  private _wrSocket = inject(WrSocketService);
  private _tournaments = inject(TournamentsService);
  sidebar = inject(SideNavService);
  user = inject(UserService);
  lang = inject(LanguageService);
  zendesk = inject(ZendeskChatService);
  swPwa = inject(ServiceWorkerService);
  badge = inject(BadgeService);
  env = inject(EnvironmentService);
  seo = inject(SeoService);
  jackpot = inject(JackpotService);
  groups = inject(GroupsService);
  motivateWithdrawal = inject(MotivateWithdrawalService);
  lottery = inject(LotteryService);
  ar = inject(ArabicService);
  bonusStoreService = inject(BonusStoreService);
  installApp = inject(InstallAppService);
  valentine = inject(ValentineService);


  readonly accordion = viewChild('accordion', { read: AccordionDirective });
  readonly nav = viewChild<ElementRef>('nav');

  public qatarTournament$: Observable<any> = this._tournaments.list().pipe(
    filter(list => !!list && Array.isArray(list)),
    map(list => list?.filter(t => t.slug.toLowerCase().includes('world-cup-christmas'))),
    map(list => list && list[0]),
    filter(tournament => !!tournament),
  );

  ngAfterViewInit() {
  }

  /**
   * Change UI language
   *
   * @param lang
   */
  changeLanguage(lang: string) {
    if (this._platform.isBrowser) {
      if (this._router.url === NonLocalizedRoutes.NotFound) {
        this.lang.changeLang(lang.toLowerCase());
      }

      const url = this._router.url.replace(`/${ this.lang.current }`, `/${ lang }`);
      this._router.navigateByUrl(`/${ url }`)
        .then(() => {
          this._translationService.getUserTranslates$()
            .pipe(
              first(),
              tap(() => {
                this.sidebar.close();
                this.accordion().close();
              })
            ).subscribe();
        });
    }
  }

  /**
   * User logout
   */
  logout() {
    this._wrSocket.sendEventLogout();
    this.sidebar.close();
    this._ssApi.usersSignOut().pipe(
      filter(() => this._platform.isBrowser),
      finalize(() => {
        window.location.href = '/';
      })
    ).subscribe();
  }

  installAppClick() {
    this.sidebar.close();
    this._gtm.installAppClick('app_sidebar', 'sidebar');
    this.swPwa.addAppToHomeScreen();
  }

  liveChatClick() {
    this.sidebar.close();
    this._gtm.onLiveChatClick('live_chat', 'chat');
  }

  zendeskLiveChatClick() {
    this.sidebar.close();
    this.zendesk.toggle();
    this._gtm.onLiveChatClick('live_chat', 'chat');
  }

  onLoginClick() {
    this._gtm.signInSidebarClick('login_sidebar', 'sidebar');
    this.sidebar.close();
  }

  onRegisterClick() {
    this._gtm.registerSidebarClick('register_sidebar', 'sidebar');
    this.sidebar.close();
  }

  onAccountClick() {
    this._gtm.sidebarAccountClick('login_sidebar_account', 'sidebar_account');

    if (!this.user.auth) {
      this.user.authUser();
    }

    this.sidebar.close();
  }
  onMissionsClick() {
    this._router.navigate(['/', this.lang.current, 'profile', 'account'], {queryParams: {tab: 'missions'}});
    this.sidebar.close();
  }

  onBonusesClick() {
    this._gtm.sidebarBonusesClick('login_sidebar_bonuses', 'sidebar_bonuses');
    this.sidebar.close();
  }

  onDepositClick(isDepositClick = true) {
    if (isDepositClick) {
      this._gtm.depositSidenavClick('deposit_sidebar', 'sidebar', this.user.info.id);
    }

    this.sidebar.close();
  }

  public toTournament(routeLink) {
    if (this.user.auth) {
      this._router.navigate(routeLink).then();
    } else {
      this.user.authUser().then();
    }
  }

  public onClickSubmenu(el: HTMLElement) {
    of(true).pipe(
      untilDestroyed(this),
      delay(300),
      tap(() => {
        el.scrollIntoView({ behavior: 'smooth' });
      }),
    ).subscribe();
  }
}
