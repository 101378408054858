import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '../../../../../../../core/services/user/user.service';
import { ToastMessageService } from '../../../../../../../core/modules/toast-message/toast-message.service';
import { WebSocketService } from '../../../../../../../core/services/web-socket.service';
import { AsyncPipe } from '@angular/common';
import { SafePipe } from '../../../../../../../core/shared/pipes/safe.pipe';
import { TranslatePipe } from '../../../../../../../core/shared/translation/translate.pipe';
import { AccordionDirective } from '../../../../../../../core/shared/directives/accordion.directive';
import { GenerateQrBase64Pipe } from '../../../../../../../core/shared/pipes/generate-qr-base64.pipe';
import { CryptoAddressPipe } from '../../../../../../../core/shared/pipes/crypto-address.pipe';

@UntilDestroy()
@Component({
    selector: 'app-deposit-crypto-address',
    templateUrl: './deposit-crypto-address.component.html',
    styleUrls: ['./deposit-crypto-address.component.scss'],
    imports: [
        SafePipe,
        TranslatePipe,
        AccordionDirective,
        GenerateQrBase64Pipe,
        AsyncPipe,
        CryptoAddressPipe,
    ]
})
export class DepositCryptoAddressComponent implements OnInit {
  user = inject(UserService);
  private _toastMessage = inject(ToastMessageService);
  private _ws = inject(WebSocketService);
  private _cd = inject(ChangeDetectorRef);


  @Input() field: any;

  ngOnInit() {
    // Fix ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    this._cd.detectChanges();
    this._handlerDepositStatusFromWS();
  }

  /**
   * Copy adress code by click
   * @param el
   * @param code
   */
  copy(el, code) {
    try {
      if ((navigator as any).clipboard) {
        (navigator as any).clipboard.writeText(code);
      } else if ((window as any).clipboardData) {
        (window as any).clipboardData.setData('text', code); // IE
      } else {
        this._copyToClipboard(el); // other browsers, iOS, Mac OS
      }
      this._toastMessage.success('t.clipboard');
    } catch (e) {
      this._toastMessage.error('t.browser-not-support'); // copy failed.
    }
  }

  /**
   * Copy To clipboard for other browsers
   * @param el
   */
  private _copyToClipboard(el: HTMLInputElement) {
    const oldContentEditable = el.contentEditable;
    const oldReadOnly = el.readOnly;
    try {
      el.contentEditable = 'true'; // specific to iOS
      el.readOnly = false;
      this._copyNodeContentsToClipboard(el);
    } finally {
      el.contentEditable = oldContentEditable;
      el.readOnly = oldReadOnly;
    }
  }

  /**
   * Copy node contents to clipboard for IOS
   * @param el
   */
  private _copyNodeContentsToClipboard(el: HTMLInputElement) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(el);
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);
    document.execCommand('copy');
  }

  /**
   * Handler from webSoket payment status
   */
  private _handlerDepositStatusFromWS() {
    this._ws.payments$.pipe(
      untilDestroyed(this),
    ).subscribe((e: any) => {
      if (e.success) {
        this._toastMessage.success('t.deposit-success');
        this.user.fetchAllUserData();
      } else {
        this._toastMessage.error('t.deposit-error');
      }
    });
  }

}
