import { inject, Injectable } from '@angular/core';
import { CookieService, WindowService } from 'ngx-unificator/services';
import { delay, distinctUntilChanged, filter, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { GlobalEventsService } from './global-events.service';
import { GroupsService } from './groups.service';
import { LanguageService } from './language/language.service';
import { FIRST_TIME_DEP, UserService } from './user/user.service';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { CmsApiService } from './api/cms-api.service';
import { AB_TEST_LIST } from '../ab-test/ab-test.data';
import { ScriptLoaderService } from './script-loader.service';
import { AbTestNewService } from '../ab-test/ab-test.service';
import { Router } from '@angular/router';


declare global {
  interface Window {
    OneSignal: any;
    OneSignalDeferred: any;
  }
}

declare var OneSignal;

export const EXCLUDE_PUSH_PROMPT_PAGES = [
  '/404',
  '/403',
  'login',
  'registration',
  'deposit',
  'games',
];

@Injectable({
  providedIn: 'root',
})
export class OneSignalService {
  private _platform = inject(PlatformService);
  private _globalEvents = inject(GlobalEventsService);
  private _cookie = inject(CookieService);
  private _groups = inject(GroupsService);
  private _language = inject(LanguageService);
  private _user = inject(UserService);
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);
  private _events = inject(GlobalEventsService);
  private _scriptLoader = inject(ScriptLoaderService);
  private _abTest = inject(AbTestNewService);
  private _window = inject(WindowService);
  private _router = inject(Router);

  private _isLoaded = false;

  private _resolveOnesignal$ = this._api
    .staticContentItem({ slug: 'ss-one-signal' })
    .pipe(
      first(),
      map(response => this._mapper.mapCmsData(response.data && response.data.item, { name: 'name' })[0]),
      tap(async data => {
        if (data?.name) {
          await this._scriptLoader.load('https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js').toPromise();
          this._isLoaded = true;
        }
      }),
      tap(data => this._onInit(data?.name)),
    )

  /**
   * Get one signal id from CMS
   */
  handleId() {
    if (this._platform.isBrowser) {
      this._abTest
        .loadABTest$('DEVSS6810')
        .pipe(
          filter(abTest => abTest === AB_TEST_LIST.DEVSS6810.abTestGroupIds.V2),
          switchMap((e) => this._resolveOnesignal$)
        )
        .subscribe();
    }
  }

  public listenOnesignal() {
    this._events.routerNavigationEnd$
      .pipe(
        distinctUntilChanged(),
        filter(() => this._isLoaded),
        takeWhile(() => !this._window.nativeWindow?.OneSignal?.User?._currentUser?.hasOneSignalId, true),
        filter(data => EXCLUDE_PUSH_PROMPT_PAGES.every(page => !data?.url?.includes(page)) && data.url.split('/').length !== 2),
        delay(10000),
        tap(data => {
          if (!EXCLUDE_PUSH_PROMPT_PAGES.some((e => this._router.url.includes(e))) && this._router.url.split('/').length !== 2) {
            this._window.nativeWindow?.OneSignal?.Slidedown?.promptPush();
          }
        }),
        tap(() =>
          this._cookie.set(
            'user_push_subscribe',
            `${!!(this._window.nativeWindow?.OneSignal?.User?._currentUser?.hasOneSignalId && this._window.nativeWindow?.OneSignal?.User?.PushSubscription?.optedIn)}`,
            999,
            '/',
          ),
        ),
      )
      .subscribe();
  }
  /**
   * Init one signal and apply id from CMS
   */
  private _onInit(id: string): void {
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async () => {
      await OneSignal.init({
        appId: id,
        notifyButton: {
          enable: true,
        },
        serviceWorkerParam: { scope: '/push/onesignal/' },
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
      }).then(() => {
        this._autoUpdateUser();
        this._setExternalId();
      });
    });

    this.listenOnesignal();
  }
  // This function will work only if Onesignal init. So it will don`t work for localhost or stage
  // And will not send test users data. To make it work change in Onesignal admin panel in app test url to localhost
  private _autoUpdateUser() {
    this._globalEvents.routerNavigationEnd$
      .pipe(
        distinctUntilChanged(),
        tap(data => {
          OneSignal.push(() => {
            this._setUserTags(this._createUserTagsObj());
          });
        }),
      )
      .subscribe();
  }

  private _setUserTags(data) {
    OneSignal?.User?.addTags(data);
  }

  private _createUserTagsObj() {
    const groups = Object.values(AB_TEST_LIST).map(e => e.resolvedValue);
    const resultObject = {};
    groups?.forEach(key => {
      resultObject[key] = true;
    });

    if (this._user.auth) {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        isUserHadDeposit: (this._groups.isExistGroup(FIRST_TIME_DEP) || this._user.accountList.some(e => e.amount > 0))?.toString(),
        userLanguage: this._language.current,
        isHaveBalance: this._user.accountList.some(e => e.amount > 0)?.toString(),
        ...resultObject,
      };
    } else {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        userLanguage: this._language.current,
        ...resultObject,
      };
    }
  }

  private _setExternalId() {
    OneSignal.push(async () => {
      if (this._window.nativeWindow?.OneSignal?.User?.PushSubscription?.id) {
        OneSignal.login(OneSignal.User.PushSubscription.id);
      }
    });
  }
}
