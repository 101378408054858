import { Component, forwardRef, inject, Injector, OnInit, viewChild } from '@angular/core';
import { UserPhoneService } from '../../../services/user/user-phone.service';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { CommonDataService, CountryList$ } from '../../../services/common-data.service';
import { EnvironmentService } from '../../../services/environment.service';
import { first, tap } from 'rxjs/operators';
import { TranslatePipe } from '../../translation/translate.pipe';
import { NgxMaskDirective } from 'ngx-mask';
import { AsyncPipe, NgClass } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { SetImgAltDirective } from '../../directives/set-img-alt.directive';

@Component({
    selector: 'app-phone-form',
    templateUrl: './phone-form.component.html',
    styleUrls: ['./phone-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneFormComponent),
            multi: true
        }
    ],
    imports: [SetImgAltDirective, ClickOutsideDirective, NgClass, FormsModule, ReactiveFormsModule, NgxMaskDirective, AsyncPipe, TranslatePipe]
})
export class PhoneFormComponent implements OnInit, ControlValueAccessor {
  userPhone = inject(UserPhoneService);
  env = inject(EnvironmentService);
  private _data = inject(CommonDataService);
  private injector = inject(Injector);


  /**
   * Input model
   */
  readonly inputModel = viewChild<NgModel>('inputModel');

  /**
   * Current flag country
   */
  public currentCountry: string;

  public ngControl: NgControl | null = null;

  /**
   * Search control
   */
  public search: FormControl = new FormControl('');

  /**
   * Is country list open
   */
  private _open: boolean;

  /**
   * Country list after filter
   */
  public filteredCountryList$: ReplaySubject<any[]> = CountryList$;

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  set value(val) {
    this.onChange(val);
    this.onTouch(val);
  }

  get isOpen(): boolean {
    return this._open;
  }

  constructor() {
    this.env.env$.pipe(
      first(),
      tap(({ data }) => {
        this.onSelect(data.country);
      }),
    ).subscribe();
  }

  ngOnInit() {
    this._onHandleSearch();
    this.ngControl = this.injector.get(NgControl, null);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  /**
   * Open countries list
   */
  onOpen() {
    this._open = true;
  }

  /**
   * Close countries list
   */
  onClose() {
    this._open = false;
  }

  onSelect(country) {
    this.onClose();
    this.currentCountry = country.short;
    this.userPhone.updatePrefixStore(country?.callingCode);
    this.userPhone.updateMaskStore(country?.short);
    this.inputModel()?.reset();
  }

  /**
   * Handle search form and filter countries
   *
   * @private
   */
  private _onHandleSearch() {
    this.search.valueChanges.pipe(
      tap(value => this.filteredCountryList$.next(
        this._data.countryList.filter(country => country.name.toLowerCase().includes(value.toLowerCase())),
      )),
    ).subscribe();
  }

}
