import { SsWebSocketsPrivateChanel } from '../../vendor/ss-web-sockets/ss-web-sockets.types';

export enum CustomNotificationChanel {
  INSTALL_APP = 'install_app',
  PROMO_CHANNEL = 'promo_channel',
  XMAS_NOTIFY = 'xmas_notify',
  MASCOT_NOTIFY = 'mascot_notify'
}

const additionalSocketsFields = {
  [SsWebSocketsPrivateChanel.FREESPINS_CHANGED]: {
    templateTitle: 'btn.get-bonus',
    icon: '/assets/img/notification/free-spins.png',
    link: '/profile/account',
  },
  [SsWebSocketsPrivateChanel.GAME_LIMITS]: {
    templateTitle: 't.more-info',
    icon: '/assets/img/notification/game-limits.png',
    link: '/',
  },
  [SsWebSocketsPrivateChanel.BONUSES_CHANGED]: {
    templateTitle: 'btn.get-bonus',
    icon: '/assets/img/notification/bonus.png',
    link: '/profile/account',
  },
  [SsWebSocketsPrivateChanel.PAYMENTS_CHANGED]: {
    templateTitle: 't.more-info',
    icon: '/assets/img/notification/coins.png',
    link: '/profile/history',
  },
  [SsWebSocketsPrivateChanel.LOOTBOXES_CHANGED]: {
    templateTitle: 'btn.get-bonus',
    icon: '/assets/img/notification/box.png',
    link: '/profile/account',
  },
  [SsWebSocketsPrivateChanel.TOURNAMENTS_STATUSES]: {
    templateTitle: 'btn.play',
    icon: '/assets/img/notification/tournaments.png',
  },
  [SsWebSocketsPrivateChanel.TOURNAMENTS_STARTED]: {
    templateTitle: 'btn.play',
    icon: '/assets/img/notification/tournaments.png',
  },
  [SsWebSocketsPrivateChanel.GROUPS_UPDATES]: {
    templateTitle: 't.more-info',
    icon: '/assets/img/notification/samurai.png',
    link: '/loyalty-program',
  },
  [CustomNotificationChanel.INSTALL_APP]: {
    templateTitle: 't.how-install',
    icon: '/assets/img/notification/install_app.png',
    link: '/mobile-app',
    title: 't.install-our-app'
  },
  [CustomNotificationChanel.PROMO_CHANNEL]: {
    templateTitle: 't.notify-promo-button',
    icon: '/assets/img/notification/halloween.png',
    link: '/promo/halloween?utm_source=halloween&utm_medium=notification&utm_campaign=notification_click',
    title: 't.notify-promo-title',
    customClass: 'halloween',
  }
};

export const additionalLootboxFields = {
  spinder_bonus_landing: {
    link: '/promo/valentine',
    description: 't.notify-spinder-lootbox-desc'
  }
};

export default additionalSocketsFields;
